export const environment = {
  production: false,
  environmentName: 'qa',
  tawkUrl: 'https://embed.tawk.to/602a4b08918aa261273ef147/1euija6oo',
  oAuth2Endpoint: 'https://2fafe.qa.gpi-pulse.com/',
  gatewayApiEndpoint: 'https://pulsebe.qa.gpi-pulse.com/api',
  gatewayApiEndpointHorizon: 'https://horizonbe.qa.gpi-pulse.com/api',
  gatewayApiEndpointImportExport: 'http://10.4.0.220',
  b2cPolicies: {
    clientId: '9834dd61-b0e2-4c96-97e7-6298f291c6ff',
    authorityDomain: 'heliosdev.b2clogin.com',
    names: {
      signUpSignIn: 'B2C_1_sisu',
      forgotPassword: 'B2C_1_passwordreset',
      editProfile: 'B2C_1_editprofile'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://heliosdev.b2clogin.com/heliosdev.onmicrosoft.com/B2C_1_signin',
      },
      forgotPassword: {
        authority: 'https://heliosdev.b2clogin.com/heliosdev.onmicrosoft.com/B2C_1_passwordreset',
      },
      editProfile: {
        authority: 'https://heliosdev.b2clogin.com/heliosdev.onmicrosoft.com/B2C_1_editprofile'
      }
    }
  },
  apiConfig: {
    scopes: ['https://heliosdev.onmicrosoft.com/00192ae6-9d2b-41b1-a253-1353b6b7bfa8/datastore.query'],
    uri: 'https://pulsebe.qa.gpi-pulse.com/api/*'
  },
  horizonApiConfig: {
    scopes: ['https://heliosdev.onmicrosoft.com/00192ae6-9d2b-41b1-a253-1353b6b7bfa8/datastore.query'],
    uri: 'https://horizonbe.qa.gpi-pulse.com/api/*'
  },
  appInsights: {
    instrumentationKey: '9b46c349-1608-4bb0-a0c8-74187dbe8855',
    dependencyLogging: 'false',
    pageViewLogging: 'false',
  },
  googleAnalytics: {
    key: 'G-5JJ7TP6QY5'
  },
  builderIo: {
    key: '3c0d498f824c4afdb8210bfcdf871064'
  }
};
